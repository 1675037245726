export const COUNTRY_CODES = [
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
  },
];
